import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
    AMPContentItem,
    Artist,
    DamService,
    EditMovieModalComponent,
    LibraryComponentService,
    Tag,
} from 'amp-ng-library';
import { UnSub } from 'amp-ng-library';
import { cloneDeep } from 'lodash';
import { msToDurationFormat } from 'amp-ng-library';

@Component({
    selector: 'app-content-mode-wrapper',
    templateUrl: './content-mode-wrapper.component.html',
    styleUrls: ['./content-mode-wrapper.component.scss'],
})
export class ContentModeWrapperComponent extends UnSub implements OnInit {
    contentItems: Array<any>;
    tags: Array<Tag>;
    artists: Array<Artist>;

    constructor(
        private libraryMenuService: LibraryComponentService,
        private modalService: NgbModal,
        private damService: DamService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.contentItems = this.libraryMenuService.getContentItems();
        this.tags = this.libraryMenuService.getConteModeTagsAndArtists().tags;
        this.artists = this.libraryMenuService.getConteModeTagsAndArtists().artists;
    }

    get multiSelectListMediator() {
        return this.libraryMenuService.getMultiSelectListMediator();
    }

    openDamModal() {
        let options: NgbModalOptions = {
            size: 'lg',
            centered: true,
            modalDialogClass: 'w-75',
            backdrop: 'static',
            keyboard: false,
        };

        const modalRef = this.modalService.open(EditMovieModalComponent, options);

        const selectedIdentifier = this.multiSelectListMediator.getSelectedIdentifiers().find(identifier => {
            return identifier;
        });

        const contentItem = this.libraryMenuService.getContentItems().find(content => {
            return content.identifier === selectedIdentifier;
        });
        this.getContentItemDeep(contentItem.id).then((contentItemDeep: AMPContentItem) => {
            const contentItemCopy = cloneDeep(contentItemDeep);
            if (contentItemCopy) {
                modalRef.componentInstance.contentItem = contentItemCopy;
                modalRef.componentInstance.duration = this.getDuration(contentItemCopy)
                modalRef.componentInstance.mediaStart = this.getMediaStart(contentItemCopy)
                modalRef.componentInstance.mediaEnd = this.getMediaEnd(contentItemCopy)
                modalRef.componentInstance.tags = this.tags;
                modalRef.componentInstance.artistNames = this.artists;
            }
            modalRef.result.then(
                (updateLibrary: boolean) => {
                    if (updateLibrary) {
                        this.libraryMenuService.startUpdateLibrary();
                    }
                },
                (rejectReason: any) => {
                    // modal closed with click outside
                },
            );
        });
    }

    getContentItemDeep(id: number): Promise<AMPContentItem> {
        return this.damService.fetchContentItemById(id).toPromise();
    }

    // getDuration(item: AMPContentItem) {
    //     let duration = item.mediaEnd - item.mediaStart;
    //     if (duration <= 0) {
    //         duration = item.duration;
    //     }
    //     return msToDurationFormat(duration);
    // }

    getDuration(item: AMPContentItem) {
        return msToDurationFormat(item.duration);
    }

    getMediaStart(item: AMPContentItem) {
        return msToDurationFormat(item.mediaStart);
    }

    getMediaEnd(item: AMPContentItem) {
        return msToDurationFormat(item.mediaEnd);
    }
}
