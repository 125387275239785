<ul class="navbar-nav mr-auto d-flex justify-content-between">

    <li class="nav-item  p-0">
        <a class="amp-tab" routerLink="/library/audio/dam" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">AUDIO LIBRARY</a>
    </li>

    <li class="nav-item  p-0">
        <a class="amp-tab" routerLink="/library/video/dam" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">VIDEO LIBRARY</a>
    </li>

    <!-- PRODUCTS TAB -->
    <li class="nav-item  p-0">
        <a class="amp-tab" routerLink="/library/product" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">PRODUCTS</a>
    </li>

    <li class="nav-item  p-0">
        <a class="amp-tab" routerLink="/library/tags" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">TAGS</a>
    </li>

    <li class="nav-item  p-0">
        <a class="amp-tab" routerLink="/library/ingest" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">INGEST</a>
    </li>

</ul>

